import { ArrayInput, BooleanField, BooleanInput, Button, Datagrid, DateField, fetchUtils, FileField, FileInput, FormDataConsumer, FormTab, Labeled, NumberField, Pagination, ReferenceField, ReferenceInput, ReferenceManyField, required, SelectField, SelectInput, SimpleFormIterator, TabbedForm, TextField, TextInput, useListContext, useRecordContext, usePermissions, DeleteButton, WrapperField } from 'react-admin';
import { ButtonGroup, Divider } from '@mui/material';
import { SizeField } from '../components/SizeField'
import { beutifySize } from '../utils/beutifySize';
import { TariffTypes } from '../tariffs/TariffTypes'
import { Countries } from './Countries';
import { Currencies } from './Currencies';
import { TariffPeriodExpandSelector } from "../tariffperiods/TariffPeriodExpandSelector";
import { AddPersonalPeriodButton } from "../tariffperiods/AddPersonalPeriodButton";
import { PersonalPeriodPaymentTypes } from "../tariffperiods/PersonalPeriodPaymentTypes";
import { AddTrialPeriodButton } from "../tariffperiods/AddTrialPeriodButton";
import { useCallback, useEffect, useState } from 'react';
import { baseUrl, httpClient } from '../dataProvider';
import { AddInvoiceFileButton } from '../invocefiles/AddInvoiceFileButton';
import { InvoiceFileArchiveButton } from '../invocefiles/InvoiceFileArchiveButton';

const PeriodStates = [
    {id: 0, name: 'Ошибка'},
    {id: 10, name: 'Отменен'},
    {id: 20, name: 'Завершился'},
    {id: 22, name: 'Грейс период'},
    {id: 25, name: 'Ожидание подтверждения'},
    {id: 30, name: 'Активен'},
    {id: 40, name: 'Запланирован'}
]
 
const Limits=(props:any)=>{
    const list = useListContext();
    //console.log('LIST',list);
    const [values, setValues] = useState<any>(null);
    const record = useRecordContext(props);
    const fetch = useCallback(()=>{
        httpClient(`${baseUrl}/companies/limits/${record.id}`)
        .then((data)=> setValues(data.json) );
    },[record?.id])
    useEffect(()=>{
        if(list.isFetching)
        {
            fetch();
        }
    },[list.isFetching, fetch])
    var expire = values?.limits?.expire;
    if(expire) {
        expire = new Date(Date.parse(expire));
        if(expire.getFullYear()==9999) expire = 'Не требуется'
        else if(expire.getFullYear()==1) expire = 'Ожидает обновления'
        else expire = expire.toISOString();
    }
    return (
    <>
        <p>Ограничение плееров : {values?.limits?.devices ?? 'Безлимит'}</p>
        <p>Используется плееров : {values?.usage?.devices}</p>
        <p>Заблокированно плееров : {values?.usage?.devicesBlocked}</p>
        <Divider/>
        <p>Ограничение хранилища : {values?.limits?.storage ? beutifySize(values?.limits.storage) : 'Безлимит'}</p>
        <p>Используется хранилища : {beutifySize(values?.usage?.storage)}, учитывается файлов {values?.usage?.contents}шт</p>
        <p>Заблокированно хранилища : {beutifySize(values?.usage?.storageBlocked)}, учитывается файлов {values?.usage?.contentsBlocked}шт</p>
        <p>Зарезервировано хранилища : {beutifySize(values?.usage?.storageReserved)}</p>
        <Divider/>
        <p>Ограничение пользователей : {values?.limits?.users ?? 'Безлимит'}</p>
        <p>Используется пользователей : {values?.usage?.users}</p>
        <p>Заблокированно пользователей : {values?.usage?.usersBlocked}</p>
        <Divider/>
        <p>Дата пересчета лимитов : {expire}{/*<Button label='Запросить пересчет'/>*/}</p>
    </>
    );
}

export const CompanyForm=(props:any)=>{
    const {permissions} = usePermissions();
    return (
        <TabbedForm {...props}>
            <FormTab label="Описание">
                <TextInput source="name" label="Название" validate={required()} />
                <ReferenceInput source="distibutorId" label="Дистрибьютор" reference="distibutors" allowEmpty={true}>
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <BooleanInput source="blocked" label="Заблокировать"/>
                <FormDataConsumer>
                    {({formData})=> {
                        if(!formData.blocked) return <div/>;
                        return (<TextInput source="blockMessage" label="Сообщение при блокировке" fullWidth/>)
                    }}
                </FormDataConsumer>
                <TextInput source="info.country" label="Страна" validate={required()}/>
                <TextInput source="info.currency" label="Валюта"/>
                <TextInput source="info.name" label="Название Юр.лица" />
                <TextInput source="info.identifier" label="Идентификатор Юр.лица(ИНН)" />
                <TextInput source="info.personName" label="ФИО контактного лица" validate={required()} />
                <TextInput source="info.personEmail" label="Email контактного лица" validate={required()} />
                <TextInput source="info.personPhone" label="Телефон контактного лица" />
                <TextInput source="info.comment" label="Комментарий" fullWidth multiline />
                
            </FormTab>
            <FormTab label="Тариф">
                <ReferenceManyField
                    reference="tariffperiods"
                    target="company_id"
                    label="Периоды"
                    fullWidth
                    pagination={ <Pagination /> }
                    sort={{field:'state', order: 'DESC'}}
                >
                    <Labeled label="Текущие лимиты" fullWidth>
                        <Limits fullWidth/>
                    </Labeled>
                    <TextInput source="personalTarifGracePeriodDays" label="Грейс период дней" disabled={!permissions || !permissions.includes("Admin")} />
                    <ButtonGroup fullWidth sx={{marginBottom:'1em'}}>
                        <AddTrialPeriodButton/>
                        <AddPersonalPeriodButton style={{marginLeft:'1rem'}}/>
                    </ButtonGroup>
                    
                    <Datagrid expand={TariffPeriodExpandSelector} rowClick='expand' bulkActionButtons={false} sx={{ width:'100%' }}>
                        <NumberField source="number" label="№" sortable={true}/>
                        <SelectField source="state" label="Состояние" choices={PeriodStates} sortable={true}/>
                        <DateField source="startTime" label="Начало" showTime sortable={true}/>
                        <DateField source="expireTime" label="Конец" showTime sortable={true}/>
                        <DateField source="graceTimeExpire" label="Грейс" showTime sortable={true}/>
                        <DateField source="cancelTime" label="Отменен" showTime sortable={true}/>
                        <SelectField source="tariff.type" label="Тип" choices={TariffTypes} sortable={true}/>
                        <NumberField source="tariff.numberOfDevices" label="Количество плееров" sortable={true}/>
                        <SizeField source="tariff.storageSize" label="Размер хранилища" textAlign="right" sortable={true}/>
                        <NumberField source="tariff.numberOfUsers" label="Количество пользователей" sortable={true}/>
                        <SelectField source="details.paymentType" choices={PersonalPeriodPaymentTypes} label="Тип оплаты" sortable={false}/>
                        <NumberField source="details.sum" label="Сумма" sortable={false}/>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Счета">
                <ReferenceManyField
                    reference="invoicefiles"
                    target="company_id"
                    fullWidth
                    pagination={ <Pagination /> }
                    sort={{field:'created', order: 'DESC'}}>
                    <AddInvoiceFileButton/>
                    <Datagrid bulkActionButtons={false} sx={{ width:'100%' }}>
                        <DateField source="created" label="Создан" showTime sortable={true}/>
                        <DateField source="archived" label="Архивирован" showTime sortable={true}/>
                        <FileField source="file.src" title="file.title" target="_blank" label="Файл" sortable={true} sortBy='name'/>
                        <WrapperField>
                        <InvoiceFileArchiveButton/>
                        <DeleteButton/>
                        </WrapperField>
                    </Datagrid>
                </ReferenceManyField>
            </FormTab>
            <FormTab label="Пользователи">
                <ArrayInput source="users" label="">
                    <SimpleFormIterator>
                        <TextInput source="name" label="Имя" validate={required()} />
                        <TextInput source="email" label="Email" validate={required()} />
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
            <FormTab label="Документы">
                <ArrayInput source="documents" label="">
                    <SimpleFormIterator>
                        <FileInput source="file" label="Документ">
                            <FileField source="src" title="title" target="_blank"/>
                        </FileInput>
                        <TextInput source="comment" label="Комментарий" fullWidth={true}/>
                    </SimpleFormIterator>
                </ArrayInput>
            </FormTab>
        </TabbedForm>
    );
}