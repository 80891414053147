import React from "react";
import { ReferenceInput, SelectInput, SimpleForm, TextInput, usePermissions } from 'react-admin';

import {userTypes} from './types';

export const UserForm=(props:any)=>{
    const {permissions} = usePermissions();
    return (
        <SimpleForm {...props}>
            <TextInput source="name" label="Имя"/>
            <TextInput source="email" label="Email"/>
            <ReferenceInput source="companyId" label="Компания" reference="companies">
                <SelectInput optionText='name'/>
            </ReferenceInput>
            {permissions && permissions.includes("Admin") && (
                <SelectInput source="type" label="Тип" choices={userTypes}/>
            )}
        </SimpleForm>
    );
}